import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { heading, metaDescription, metaKeywords, title } from 'constants/meta';
import cartQuery from 'graphql/queries/cart.graphql';
import MainTemplate from 'components/templates/MainTemplate';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import Alert from 'components/atoms/Alert';
import QueryFromCacheAfterSSRThenNetworkOnly from 'components/atoms/QueryFromCacheAfterSSRThenNetworkOnly';
import ButtonLink from 'components/atoms/ButtonLink';
import Table from './Table';
import Summary from './Summary';

const Title = styled.h1`
  font-size: 2em;
  font-weight: normal;
`;

const EmptyCartMessage = styled.p`
  text-align: center;
  font-style: italic;
  margin: 3em 0;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 2em;
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex-wrap: nowrap;
  }
`;

const BackToShopButtonLink = styled(ButtonLink)`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    order: 1;
    width: auto;
  }
`;

const SummaryRowRightCol = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    order: 2;
    flex-wrap: nowrap;
  }
`;

const StyledSummary = styled(Summary)`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin: 0 1em;
  }
`;

const CheckoutButtonLink = styled(ButtonLink)`
  margin: 1em 0;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin: 0;
    width: auto;
  }
`;

const CartPage = () => (
  <QueryFromCacheAfterSSRThenNetworkOnly query={cartQuery}>
    {({ data, loading, error }) => {
      if (loading) {
        return <LoadingPage />;
      }

      if (error) {
        return <ErrorLoadingPage />;
      }
      return (
        <MainTemplate>
          <Helmet>
            <title>{title.cart}</title>
            <meta name="keywords" content={metaKeywords.cart} />
            <meta name="description" content={metaDescription.cart} />
          </Helmet>
          <Title>{heading.cart}</Title>
          {data.cart.total < 3000 && (
            <StyledAlert type="error" narrow>
              <strong>Внимание!</strong> Минимальная сумма для заказов с доставкой по РФ - 1500
              рублей!
            </StyledAlert>
          )}
          {data.cart.cartItems.length === 0 && <EmptyCartMessage>Корзина пуста</EmptyCartMessage>}
          {data.cart.cartItems.length > 0 && <Table cartItems={data.cart.cartItems} />}
          {data.cart.cartItems.length > 0 && (
            <SummaryRow>
              <SummaryRowRightCol>
                <StyledSummary cart={data.cart} />
                <CheckoutButtonLink primary inline big to="/cart/checkout">
                  Оформить заказ
                </CheckoutButtonLink>
              </SummaryRowRightCol>
              <BackToShopButtonLink neutral inline to="/">
                Продолжить покупки
              </BackToShopButtonLink>
            </SummaryRow>
          )}
        </MainTemplate>
      );
    }}
  </QueryFromCacheAfterSSRThenNetworkOnly>
);

export default CartPage;
